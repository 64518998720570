import React, { Fragment } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Services = () => {
    const data = useStaticQuery(graphql`
        query ServicesQuery {
            services: contentfulAbout {
                services {
                    id
                    name
                    icon {
                        gatsbyImageData(layout: FIXED, width: 80)
                    }
                }
            }
        }
    `);
    const { services } = data;
    return (
        <Fragment>
            {/* services */}
            <h2 className="latest-heading py-5 m-0 text-center">
                <span>خدماتنا</span>
            </h2>
            <div className="row align-items-center justify-content-center container-white p-5">
                {services.services.map((service) => {
                    return (
                        <section
                            className="col-md-3 my-4 text-center services"
                            key={service.id}
                            data-sal="zoom-in"
                            data-sal-delay="150"
                            data-sal-easing="ease-in">
                            <GatsbyImage image={service.icon.gatsbyImageData} className="img-fluid" />
                            <p className="text-center my-3">{service.name}</p>
                        </section>
                    );
                })}
                <div className="col-md-12 text-center my-4">
                    <Link to={'/contact/'} className="link">
                        للاستفسار والحجز
                    </Link>
                </div>
            </div>
        </Fragment>
    );
};
export default Services;
