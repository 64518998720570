import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Services from '../components/services';
import signature from '../images/signature.png';
const About = ({ data: { shaghbatta: { title, description, image } } }) => (
    <Layout>
        <Seo
            title={`${title}`}
            description={description.description}
            keywords={[ 'الصحه العامه', 'الصحه النفسيه', 'شغبطه بقلم د.هبه اللكاوي', 'shaghbatta' ]}
        />
        <div className="container my-5">
            <h2 className="latest-heading py-5 m-0 text-center">
                <span>{title}</span>
            </h2>
            <div className="row justify-content-center container-white align-items-center">
                <div className="col-md-6 my-3">
                    <div className="m-4" data-sal="slide-right" data-sal-delay="150" data-sal-easing="ease-in">
                        <GatsbyImage image={image.gatsbyImageData} className="rounded img-fluid" />
                    </div>
                </div>
                <div className="col-md-6 my-3">
                    <div
                        className="author-brief m-4"
                        data-sal="slide-left"
                        data-sal-delay="150"
                        data-sal-easing="ease-in">
                        <p style={{ lineHeight: '1.8' }}>{description.description}</p>
                        <p className="text-left">
                            <img src={signature} style={{ width: 150 }} alt="Heba Ellekkawy arabic signature" />
                        </p>
                    </div>
                </div>
            </div>
            {/* services */}
            <Services />
        </div>
    </Layout>
);
export default About;
export const pageQuery = graphql`
    query ShaghbattaQuery {
        shaghbatta: contentfulAbout {
            title
            description {
                description
            }
            image {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`;
